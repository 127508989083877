import axios from "axios";

let hostname = window.location.hostname;
console.log('sub', hostname)
// let appType = null;
let connectorUrl = null;
let paymentsUrl = null;

if(hostname === "lk.mk-mobile.online" || hostname === "lk.pochastiam.ru" || hostname === "lk.letopay.ru") {
    connectorUrl = "https://bnpl.mk-mobile.online/";
    paymentsUrl =  "https://paylate.ru/API/v1/payments/";
}
else if(hostname === "test-lk.mk-mobile.online" || hostname === "localhost") {
    connectorUrl = "https://achupeev.paylate.ru/";
    paymentsUrl =  "http://dev1.paylate.ru/API/v1/payments/";
}
function proxyConnector(url, data) {
    let config = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        //боевой бек
        // url: "https://bnpl.mk-mobile.online/" + url,
        url: connectorUrl + url,
        // тестовый бек
        // url:  "https://test-api-bnpl.mk-mobile.online/" + url,
        // url:  "https://achupeev.paylate.ru/" + url,
        data: data,
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => reject(err));
    });
}

function proxyConnectorPayments(url, data) {
    let config = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        // url: "https://paylate.ru/API/v1/payments/" + url,
        // url: "http://dev1.paylate.ru/API/v1/payments/" + url,
        url: paymentsUrl + url,
        data: data,
    };

    return new Promise((resolve, reject) => {
        axios(config)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => reject(err));
    });
}

// function dadata(url, query, parts) {
//     // console.log('dadata', url, query, fetch);
//     var dadataUrl = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/";
//     var token = "8ea87af5d3bb2c80b6f051d1c6c153f26d65eef4";
//
//     var options = {
//         method: "POST",
//         mode: "cors",
//         headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: "Token " + token,
//         },
//         body: JSON.stringify({ query, parts }),
//     };
//
//     return fetch(dadataUrl + url, options).then((response) => response.json());
//     // .then(result => console.log(result))
//     // .catch(error => console.log("error", error));
// }

/*function roundDot2(number) {
    return Math.round(number * 100) / 100;
}*/


export default {
    data() {
        return {
            connector: {
                isMainInformationCalc: (get_param) => {
                    const token = "";
                    const data = {
                        s: get_param,
                    };
                    return proxyConnector("cash/get_params", data, token);
                },

                accountInfo: () => {
                    const token = localStorage.getItem("token") || "";
                    const bnplParentId = localStorage.getItem("bnplParentId") || "";
                    const data = {
                        apicustaccinfo: {
                            bnplParentId,
                            token,
                        },
                    };

                    return proxyConnector("custaccinfo", data);
                },

                changeShedule: (value, onlyCost = true, code = 0) => {
                    const token = localStorage.getItem("token") || "";
                    const data = {
                        apichangeschedule: {
                            token, //токен из метода авторизации
                            bnplOrderId: this.$root.bnplOrderId, //appid из метода /custaccinfo/
                            countNewPayments: value, //Если выбран пункт 6 мес., то 6. Если 12, то 12. По умолчанию выбираем на форме - 6 мес.
                            onlyCost: onlyCost, //При переключениях true
                            smsCode: code, //При переключениях чек бокса 0
                            descr: "", //Всегда пустое значение
                        },
                    };

                    return proxyConnector("changeschedule", data);
                },

                addCard: (account) => {
                    const token = localStorage.getItem("token") || "";
                    const bnplParentId = localStorage.getItem("bnplParentId") || "";

                    let href = window.location.href.replace(/\/$/,'/?').replace(/&title=[0-9]*/g, '').replace(/&bnplOrderId=[0-9]*/g, '').replace(/&redirect=(true|false)/g, '');

                    let url = `${href}&title=${account}&bnplOrderId=${this.$root.bnplOrderId}`;

                    let type_id = this.$root.appType === 5 ? 3 : 2;

                    if(this.$root.appType === 7) {
                        type_id = 6;
                    }

                    const data = {
                            token, //токен из метода авторизации
                            bnplParentId: bnplParentId,
                            sum_1: 10,  // постоянное значение - 10
                            "fail_url": url + '&redirect=false',  // Адрес для НЕуспеха
                            "success_url": url + '&redirect=true',  // Адрес для успеха
                            "is_recurring": true,  // Постоянное значение TRUE
                            "type_id": type_id,  // Для PL (assignment 3, appType 4) - 2, для Ренессанса (assignment 4, appType 5) - 3
                            "bnplOrderId": this.$root.bnplOrderId,  // appId по которой происходит привязка
                            "account": account,  // title, по которому происходит привязка
                            "is_auth": true // Постоянное значение TRUE
                    };

                    return proxyConnector("cards/addcard", data);
                },

                getcards: () => {
                    const token = localStorage.getItem("token") || "";
                    const bnplParentId = localStorage.getItem("bnplParentId") || "";

                    const data = {
                            token, //токен из метода авторизации
                            bnplParentId: bnplParentId,
                    };

                    return proxyConnector("cards/getcards", data);
                },

                selectCard: () => {
                    const token = localStorage.getItem("token") || "";
                    const bnplParentId = localStorage.getItem("bnplParentId") || "";

                    const data = {
                        token, //токен из метода авторизации
                        bnplParentId: bnplParentId,
                        appId: this.$root.bnplOrderId,
                        payId: this.$root.payId,
                    };

                    return proxyConnector("cards/altercards", data);
                },


                getDocument: () => {
                    const token = localStorage.getItem("token") || "";
                    const bnplParentId = localStorage.getItem("bnplParentId") || "";

                    const data = {
                        token, //токен из метода авторизации
                        bnplParentId: bnplParentId,
                        appId: this.$root.bnplOrderId,
                        "docType": 18  // постоянное значение - 18
                    };

                    return proxyConnector("Documents/GetDocument", data);
                },

                b2p_recurring: () => {
                    const token = localStorage.getItem("token") || "";
                    const data = {
                        token, //токен из метода авторизации
                        id: this.$root.bnplOrderId, //appid из метода /custaccinfo/
                        amount: this.$root.incomingData.amountPay, //Если выбран пункт 6 мес., то 6. Если 12, то 12. По умолчанию выбираем на форме - 6 мес.
                        fee: 0, //всегда 0
                    };

                    return proxyConnectorPayments("b2p_recurring", data);
                },

                changePaymentDates: (value, smsCode, isSlider) => {
                    const token = localStorage.getItem("token") || "";
                    const data = {
                        apichangepaymentdates: {
                            token, //токен из метода авторизации
                            bnplOrderId: this.$root.bnplOrderId, //appid из метода /custaccinfo/
                            day: value, //Если выбран пункт 6 мес., то 6. Если 12, то 12. По умолчанию выбираем на форме - 6 мес.
                            allPeriods: false, //При переключениях true
                            smsCode: smsCode, //При переключениях чек бокса 0
                            onlyCost: isSlider, // При выборе срока на слайдере - true
                            descr: "", //Всегда пустое значение
                        },
                    };

                    return proxyConnector("changepaymentdates", data);
                },

                getToken: (phone, password) => {
                    let mobilePhoneNumber = phone || (localStorage.getItem('phone') + '').replace('+', '').replace(/ /g, '')
                    const data = {
                        mobilePhoneNumber,
                        appType: this.$root.appType,
                        isBnpl: true,
                        passwordHash: password || localStorage.getItem('passwordHash'),
                    };
                    return proxyConnector("AuthV2", data);
                },

                passrecovery: () => {
                    const data = {
                        mobilePhoneNumber: this.$anketa.phone.value,
                        appType: this.$root.appType,
                        birthDay: this.$anketa.birthdate.value,
                    };
                    return proxyConnector("passrecovery", data);
                }
            },
        };
    },
    methods: {
        // proxyConnector(url, data, token) {
        //     let config = {
        //         method: "POST",
        //         url: "http://192.168.20.171:8001/" + url,
        //         headers: {
        //             "Content-Type": "application/json",
        //             Authorization: token,
        //         },
        //         data,
        //     };
        //
        //     return new Promise((resolve, reject) => {
        //         axios(config)
        //             .then((response) => resolve(response.data))
        //             .catch((err) => reject(err));
        //     });
        // },
    },
};
